import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { OcpiService } from 'src/app/services/ocpi.service';

@Component({
  selector: 'app-active-session-popup',
  templateUrl: './active-session-popup.component.html',
  styleUrls: ['./active-session-popup.component.scss'],
})
export class ActiveSessionPopupComponent implements OnInit {

  constructor(private popoverCtrl: PopoverController, private router: Router, private ocpiService: OcpiService) { }

  ngOnInit() {}

  close() {
    this.popoverCtrl.dismiss();
  }

  navigateToSession() {
    this.popoverCtrl.dismiss();
    this.ocpiService.goToActiveChargingSession();
  }

}
