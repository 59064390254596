import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { AppConfig } from "../shared/app-config";
import { UserService } from "./user.service";


@Injectable({
    providedIn: 'root'
})
export class BillingService {

    balanceChanged: Subject<number> = new Subject();

    constructor(private http: HttpClient, private userService: UserService) { }


    createCustomer() {
        const user = this.userService.getUser();
        let body = {
            "customerId": user.id,
            "name": user.username,
            "email": "",
            "mobile": user.mobile
        }
        this.http.post<any>(`${AppConfig.BILLING_URL}wallet`, body).subscribe((resp) => {
            console.log("========user created==========>", resp);
        });
    }

    getWalletBalance() {
        const user = this.userService.getUser();
        return this.http.get<any>(`${AppConfig.BILLING_URL}wallet/${user.id}`);
    }

    getStatement() {
        const user = this.userService.getUser();
        return this.http.get<any>(`${AppConfig.BILLING_URL}wallet/statement/${user.id}`);
    }

    createOrder(amount: number) {
        return this.http.post<any>(`${AppConfig.BILLING_URL}wallet/order`, {amount});
    }

    createBillingOrder(amount: number) {
        return this.http.post<any>(`${AppConfig.BILLING_URL}billing/order`, {amount});
    }

    createPayment(body: any) {
        const user = this.userService.getUser();
        body.name = user.username;
        body.mobile = user.mobile;
        body.customerId = user.id;
        return this.http.post<any>(`${AppConfig.BILLING_URL}billing/payment`, body);
    }

    refundAmmountStart(dataTo: any) {
        return this.http.post<any>(`${AppConfig.BILLING_URL}/wallet/refund`, {dataTo});
    }

    transerPayment(body: any) {
        return this.http.post<any>(`${AppConfig.BILLING_URL}wallet/transfer`, body);
    }

    downloadInvoice(cdrId) {
        let headers = new HttpHeaders().append('PDF-Content-Type', 'true');
        return this.http.get<any>(`${AppConfig.BILLING_URL}invoices/invoice/${cdrId}/download`, {
        headers, observe: 'response', responseType: 'blob' as 'json'
      });
    }

    getBalanceChangedSub$() {
        return this.balanceChanged.asObservable();
    }
    
    updateBalance(newVal: number) {
        this.balanceChanged.next(newVal);
    }
}